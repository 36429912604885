#root {

    .form-check:focus,
    a:focus,
    input:focus,
    button:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

body {
    overflow: hidden !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

table {
    tbody {

        tr,
        td {
            vertical-align: middle;
        }
    }
}


#centeredLoadButton {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 2.5rem
}

.card::after {
    margin-top: 1rem !important;
}

.tableFixHead {
    overflow: auto;
    max-height: 500px;
    position: relative;
}
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}
